import PropTypes from "prop-types";
import React from "react";
import { Slice } from "gatsby";
import BackToTop from "@components/back-to-top"; // Import the BackToTop component

const DefaultLayout = ({ children, location }) => {
  return (
    <>
      <Slice alias="header" path={location?.pathname} />
      <main className="site-main">{children}</main>
      <Slice alias="footer" path={location?.pathname} />
      {/* Include the BackToTop component */}
      <BackToTop />
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
