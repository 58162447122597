exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-annual-moisture-testing-js": () => import("./../../../src/pages/annual-moisture-testing.js" /* webpackChunkName: "component---src-pages-annual-moisture-testing-js" */),
  "component---src-pages-completed-projects-js": () => import("./../../../src/pages/completed-projects.js" /* webpackChunkName: "component---src-pages-completed-projects-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-property-inspection-in-auckland-js": () => import("./../../../src/pages/property-inspection-in-auckland.js" /* webpackChunkName: "component---src-pages-property-inspection-in-auckland-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-posts-index-js": () => import("./../../../src/templates/posts/index.js" /* webpackChunkName: "component---src-templates-posts-index-js" */),
  "component---src-templates-renovation-index-js": () => import("./../../../src/templates/renovation/index.js" /* webpackChunkName: "component---src-templates-renovation-index-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

