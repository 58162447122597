import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <button
          className="back-to-top fixed bottom-4 right-4 bg-orange hover:bg-[#9a9a9a] text-white py-4 px-4 rounded-full cursor-pointer"
          onClick={scrollToTop}
          title="Back to Top" // Add the title attribute for the tooltip
        >
          <FaArrowUp />
        </button>
      )}
    </>
  );
};

export default BackToTop;
